var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Machines"},[[_c('v-card',{staticClass:"mx-2",attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","loading":_vm.loading,"loading-text":_vm.$t('loading'),"headers":_vm.headers,"items":_vm.machines,"items-per-page":20,"footer-props":{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'items-per-page-text': _vm.$t('table.per-page')
        },"no-data-text":_vm.$t('table.no-data'),"search":_vm.search,"single-expand":true,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('machines.header')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.hasPermission('api.add_machine'))?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onNewMachine}},[_vm._v(" "+_vm._s(_vm.$t('machines.new'))+" ")]):_vm._e()],1)]},proxy:true},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.tType(item.type))+" ")]}},{key:`item.is_default`,fn:function({ item }){return [(item.is_default)?_c('v-icon',{attrs:{"size":"small","color":"success"}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [(_vm.hasPermission('api.change_machine'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(
              _vm.hasPermission('api.view_scheduletemplateevent') ||
              _vm.hasPermission('api.view_scheduleevent')
            )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.machineSchedule(item)}}},[_vm._v(" mdi-calendar-weekend ")]):_vm._e(),(_vm.hasPermission('api.delete_machine'))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('more-info',{attrs:{"item":item,"headers":headers}})],1)]}}],null,true)})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }